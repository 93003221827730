import { ReactElement, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import { useSidebarContext } from 'context/SidebarContext';
import styled from 'styled-components';

import { useAuth } from '@lib/auth';
import { EverysetUser } from '@lib/types/user.types';

import Header from './components/Header';
import HeaderMobile from './components/HeaderMobile';
const { Sider } = Layout;

const MainLayout = ({ children }: { children: ReactElement }): JSX.Element => {
  const { getImpersonatedUser } = useAuth();
  const [impersonatedUserData, setImpersonatedUserData] =
    useState<EverysetUser | undefined>();
  useEffect(() => {
    const updateImperonatedUser = async () => {
      const user = await getImpersonatedUser();
      setImpersonatedUserData(user);
    };
    updateImperonatedUser();
  }, [getImpersonatedUser]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const router = useRouter();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const { asPath } = router;
  const isProfileHighlighted = asPath.startsWith('/profile');

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (!isMobile) {
      const sidebarElement = document.getElementById('sidebar');
      if (sidebarElement) {
        sidebarElement.style.display = 'block';
      }
    }
    // When the component mounts or the route changes, update the selected menu item based on the current path
    const currentPath = router.asPath.replace(/^\//, ''); // Remove the leading slash

    // Check if the selected path is empty, which means it's the first time loading the page
    if (currentPath === '') {
      // Set the default selected key to 'Invites'
      setSelectedKeys(['jobs/Invites']);
      // Push the 'Invite' path to the router so that the URL matches the selected tab
      router.push('/jobs/Invites');
    } else {
      setSelectedKeys([currentPath]);
    }
  }, [router]);

  const getMenuPageName = (key: string): string => {
    switch (key) {
      case '/profile':
        return 'Profile';
      case 'help/PrivacyPolicy':
        return 'Privacy Policy';
      case 'help/Service':
        return 'Terms of Service';
      case 'help/HelpCenter':
        return 'Help Center';
      case 'Accounts/MyAccount':
        return 'My Account';
      case 'Accounts/ManageAccount':
        return 'Manage Accounts';
      default:
        return 'logo';
    }
  };
  const pageName = getMenuPageName(selectedKeys[0]);

  type MenuItem = Required<MenuProps>['items'][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
  ): MenuItem {
    return {
      key,
      children,
      label,
      type,
    } as MenuItem;
  }

  const items: MenuProps['items'] = [
    getItem(
      <HeadText>JOBS</HeadText>,
      'jobs',
      null,
      [
        getItem('Invites', 'jobs/Invites'),
        getItem('Avail Checks', 'jobs/AvailChecks'),
        getItem('Bookings', 'jobs/Bookings'),
      ],
      'group'
    ),

    getItem(
      <HeadText className='head-text-with-spacing'>PROFILE</HeadText>,
      'profile',
      null,
      [getItem('My Profile', 'profile')],
      'group'
    ),

    getItem(
      <HeadText className='head-text-with-spacing'>ACCOUNT</HeadText>,
      'account',
      null,
      [
        getItem('My Account', 'Accounts/MyAccount'),
        // getItem('Manage Accounts', 'Accounts/ManageAccount'), -- removed this until it's fully functional
      ],
      'group'
    ),

    getItem(
      <HeadText className='head-text-with-spacing'>HELP</HeadText>,
      'help',
      null,
      [
        getItem('Privacy Policy', 'help/PrivacyPolicy'),
        getItem('Terms of Service', 'help/Service'),
        getItem('Help Center', 'help/HelpCenter'),
      ],
      'group'
    ),
  ];

  // Check if the current page is a menu item page
  const isMenuItemPage = items.some((item: any) =>
    selectedKeys.includes(item.key)
  );

  const onClick: MenuProps['onClick'] = (e) => {
    const path = e.key as string; // Get the selected menu item's key as the path
    router.push(`/${path}`); // Use router.push to navigate to the path
    setSelectedKeys([e.key]);
  };
  const profileClassName = isProfileHighlighted ? 'profile-highlighted' : '';
  const pageSpecificClassName = `${pageName.toLowerCase()}-page ${profileClassName}`;
  const { isSidebarCollapsed, setIsSidebarCollapsed } = useSidebarContext();
  const toggleCollapsed = () => setIsSidebarCollapsed(!isSidebarCollapsed);

  return (
    <>
      {isMobile &&
        (isMenuItemPage ||
          selectedKeys.includes('jobs/Invites') ||
          selectedKeys.includes('jobs/Bookings') ||
          selectedKeys.includes('jobs/AvailChecks') ||
          selectedKeys.includes('/profile') ||
          selectedKeys.includes('help/PrivacyPolicy') ||
          selectedKeys.includes('help/Service') ||
          selectedKeys.includes('help/HelpCenter') ||
          selectedKeys.includes('Accounts/MyAccount') ||
          selectedKeys.includes('Accounts/ManageAccount') ||
          selectedKeys.includes('jobs/Invites/Invited')) && (
          <HeaderMobile
            pageName={pageName}
            impersonatedUserData={impersonatedUserData}
          />
        )}
      {!isMobile && (
        <Header
          toggleCollapsed={toggleCollapsed}
          impersonatedUserData={impersonatedUserData}
        />
      )}

      <div
        style={
          isMobile
            ? { marginTop: '2px' }
            : { display: 'flex', marginTop: '2px' }
        }
      >
        <aside>
          <Layout>
            <StyledSider
              breakpoint='sm'
              collapsedWidth='0'
              trigger={null}
              collapsible
              collapsed={isSidebarCollapsed}
              id='sidebar'
            >
              <StyledMenu
                onClick={onClick}
                defaultSelectedKeys={['Invites']}
                defaultOpenKeys={selectedKeys}
                mode='inline'
                items={items}
                selectable
                selectedKeys={selectedKeys} // Set the selected keys to highlight the menu
                style={{
                  boxShadow: 'rgba(64, 64, 64, 0.25) 1px 1px 7px 1px',
                  display: isSidebarCollapsed ? 'none' : 'block',
                  marginTop: '65px',
                }}
                className={pageSpecificClassName} // Add class based on condition
              />
            </StyledSider>
          </Layout>
        </aside>
        <main style={{ width: '100%', marginTop: !isMobile && '60px' }}>
          {children}
        </main>
      </div>
    </>
  );
};

export const getMainLayout = (page: ReactElement): JSX.Element => (
  <MainLayout>{page}</MainLayout>
);

const StyledSider = styled(Sider)`
  @media (max-width: 576px) {
    display: none;
  }
  width: 202px;
`;

const StyledMenu = styled(Menu)`
  background-color: ${(props) => props.theme.palette.greyscale[1]};
  padding-top: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 203px;
  height: calc(100vh - 65px);
  padding-left: 10px;
  position: fixed;
  z-index: 100;

  @media (max-height: 676px) {
    padding-top: 2vh;
    .ant-menu-item,
    .ant-menu-item-active {
      height: 5.8vh;
    }
  }

  .ant-menu-item:hover {
    width: 170px; /* New width on hover */
  }

  .ant-menu-item-selected {
    color: #1733c4;
    background-color: #e8ecf9 !important;
    font-weight: 600;
    width: 170px;
  }

  /* Add custom style for profile-highlighted class */
  .profile-highlighted .ant-menu-item-selected {
    color: #1733c4 !important;
    background-color: #e8ecf9 !important;
    font-weight: 600;
    margin: 10px;
    width: 160px;
  }
`;

const HeadText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #202020;

  &.head-text-with-spacing {
    margin-top: 16px;

    @media (max-height: 676px) {
      margin-top: 1vh;
    }
  }
`;

export default MainLayout;
