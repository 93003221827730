import { useState } from 'react';
import Link from 'next/link';
import router from 'next/router';
import { Drawer, Menu, MenuProps } from 'antd';
import styled from 'styled-components';

import { HamburgerMenuIcon } from '@assets/icons';
import { EverysetIcon, EverysetLogo } from '@assets/img';
import { useAuth } from '@lib/auth';
import type { EverysetUser } from '@lib/types/user.types';

interface HeaderMobileProps {
  pageName: string;
  impersonatedUserData: EverysetUser | undefined;
}

const HeaderMobile = ({
  pageName,
  impersonatedUserData,
}: HeaderMobileProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const { logout } = useAuth();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  type MenuItem = Required<MenuProps>['items'][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
  ): MenuItem {
    return {
      key,
      children,
      label,
      type,
    } as MenuItem;
  }

  const items: MenuProps['items'] = [
    getItem(
      <HeadText>JOBS</HeadText>,
      'jobs',
      null,
      [
        getItem('Invites', 'jobs/Invites'),
        getItem('Avail Checks', 'jobs/AvailChecks'),
        getItem('Bookings', 'jobs/Bookings'),
      ],
      'group'
    ),

    getItem(
      <HeadText>PROFILE</HeadText>,
      'profile',
      null,
      [getItem('My Profile', 'profile')],
      'group'
    ),

    getItem(
      <HeadText>ACCOUNT</HeadText>,
      'account',
      null,
      [
        getItem('My Account', 'Accounts/MyAccount'),
        getItem('Manage Accounts', 'Accounts/ManageAccount'),
      ],
      'group'
    ),

    getItem(
      <HeadText>HELP</HeadText>,
      'help',
      null,
      [
        getItem('Privacy Policy', 'help/PrivacyPolicy'),
        getItem('Terms of Service', 'help/Service'),
        getItem('Help Center', 'help/HelpCenter'),
      ],
      'group'
    ),
  ];

  const onClick: MenuProps['onClick'] = (e) => {
    const path = e.key as string; // Get the selected menu item's key as the path
    router.push(`/${path}`); // Use router.push to navigate to the path
    setSelectedKeys([e.key]);
    setOpen(false);
  };

  const getImpersonatedUserFullName = () => {
    if (!impersonatedUserData) {
      return '';
    }
    return `${impersonatedUserData.first_name}${
      impersonatedUserData.last_name ? ` ${impersonatedUserData.last_name}` : ''
    }`;
  };

  return (
    <StyledHeader isImpersonating={Boolean(impersonatedUserData)}>
      <StyledImgButton onClick={() => showDrawer()}>
        <HamburgerMenuIcon
          fill={impersonatedUserData ? 'white' : undefined}
          style={{ marginTop: 7 }}
        />
      </StyledImgButton>
      <HeaderSection>
        {pageName === 'logo' ? (
          <Link href='/jobs/Invites/' passHref>
            {impersonatedUserData ? (
              <EverysetIcon fill='white' height={30} width={27} />
            ) : (
              <EverysetLogo
                width={103}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                }}
                fill={impersonatedUserData ? 'white' : undefined}
              />
            )}
          </Link>
        ) : (
          <HeadTextPage>{pageName}</HeadTextPage>
        )}
        {impersonatedUserData ? (
          <div className='impersonatingMessage'>
            You are currently impersonating {getImpersonatedUserFullName()}. All
            activity will be logged.
          </div>
        ) : null}
        <CustomDrawer
          placement='left'
          width={'250px'}
          open={open}
          closable={false}
          maskStyle={{ background: '#BABABA', opacity: '40%' }}
          maskClosable={true}
          onClose={onClose}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <StyledMenu
              onClick={onClick}
              defaultSelectedKeys={['Invites']}
              defaultOpenKeys={selectedKeys}
              mode='inline'
              items={items}
              selectable
              selectedKeys={selectedKeys}
            />
            <div style={{ flex: 1 }}></div>{' '}
            {/* Create flexible space to push the <a> element to the bottom */}
            <div style={{ padding: '10px' }}>
              {' '}
              {/* Add padding for gap */}
              <LogoutText>
                {' '}
                <a
                  onClick={logout}
                  style={{
                    color: '#202020',

                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '14px',
                  }}
                >
                  Log Out
                </a>
              </LogoutText>
            </div>
          </div>
        </CustomDrawer>
      </HeaderSection>
    </StyledHeader>
  );
};

const StyledMenu = styled(Menu)`
  padding-top: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  .ant-menu-item-selected {
    color: #1733c4;
    font-weight: 600;
  }

  border-inline-menu: none;
`;
const CustomDrawer = styled(Drawer)`
  .ant-menu-inline {
    border-inline-end: 0px !important;
  }
`;

type StyledHeaderProps = {
  isImpersonating: boolean;
};

const StyledHeader = styled.header<StyledHeaderProps>`
  background: ${({ theme, isImpersonating }) =>
    isImpersonating ? theme.palette.greyscale[7] : theme.palette.greyscale[1]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  height: 65px;
  box-shadow: ${(props) => props.theme.palette.shadow[0]};
  border-inline-end: none;
`;

const HeaderSection = styled.section`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;

  .impersonatingMessage {
    color: white;
    font-size: 12px;
    flex-grow: 0;
  }
`;

const HeadText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #202020;
`;
const LogoutText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #202020;

  width: 85px;
`;
const StyledImgButton = styled.div`
  cursor: pointer;
  margin-right: 24px;
  margin-left: auto;
`;
const HeadTextPage = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #333333;
`;

export default HeaderMobile;
